import Head from 'next/head'
import Image from 'next/image'
import styles from '../styles/Home.module.css'
import { NextPage } from 'next'

const Home: NextPage = () => {
  return (
    <div className={styles.container}>
      <Head>
        <title>{`The Skip'd of Travel`}</title>
        <meta name="description" content="Skip'd corporation" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Image
        layout="fill"
        objectFit="cover"
        quality={100}
        src="/images/bg-1.jpeg"
        alt="dream big"
      />
      <main className={styles.main}>
        <h1 style={{ color: '#e4e5e6' }}>
          Where will the world find you next?
        </h1>

        <Image
          layout="intrinsic"
          src="/images/skipTempLogo.png"
          width={496}
          height={135}
          alt="The Skip'd of Travel"
        />
        <h1 style={{ color: '#e4e5e6' }}>
          We will change the way you travel. Wait for it...
        </h1>
      </main>

      <footer className={styles.footer}>
        <a href="https://skipd.com" target="_blank" rel="noopener noreferrer">
          Powered by{' '}
          <span className={styles.logo}>
            <Image
              src="/images/skipTempLogo.png"
              alt="Skipd Logo"
              width={65}
              height={18}
            />
          </span>
          Corporation, 2021
        </a>
      </footer>
    </div>
  )
}

export default Home

// TODO: Just an example the how the HOC works
//export default withAuth(Home)
